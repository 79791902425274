<template>
  <div class="question py-2 my-1">
    <div class="row" v-if="!(feedback && feedback.option_id) && !is_submitted">
      <div class="col-sm-12">
        <div class="question__body mb-2">
          <span v-html="question.title" class="quiz-black" style="color: #000;">{{ question.title }}</span>
        </div>
      </div>
    </div>
    <div class="row" v-if="!(feedback && feedback.option_id) && is_submitted">
      <div class="col-sm-10">
        <div class="question__body mb-2">
          <span v-html="question.title" class="quiz-black" style="color: #000;">{{ question.title }}</span>
        </div>
      </div>
      <div class="col-sm-2">
        <h3 class="text-danger text-right">
          0/1
        </h3>
      </div>
    </div>
    <div class="row" v-if="feedback && feedback.option_id">
      <div class="col-sm-10">
        <div class="question__body mb-2">
          <span v-html="question.title" class="quiz-black" style="color: #000;">{{ question.title }}</span>
        </div>
      </div>
      <div class="col-sm-2">
         <h3
             :class="{
            'text-success text-right': feedback.points != 0,
            'text-danger text-right': feedback.points == 0,
          }"
         >
          {{ feedback.points }}/1
        </h3>
      </div>
    </div>

    <div class="custom-control custom-radio"
         v-for="(option,idx) in options" :key="idx">
      <input type="radio" class="form-check-input"
             :id="option.id" :name="question.id" :value="option.id" @change="$emit('input',{
                    question_id: question.id,
                    option_id: option.id
                })"
             :checked="feedback && feedback.option_id === option.id"
             :disabled="is_submitted"
      >
      <label
          class="option__label"
          :class="{
              'text-danger': feedback && feedback.option_id == option.id && feedback.points == 0,
              'text-success': feedback && feedback.option_id == option.id && feedback.points != 0
          }"
          :for="option.id"
      >
        <span v-html='option.content.replace("color:", "").replace("background-color:", "").replace("background:", "")' class="d-inline-block quiz-black text-left"></span>
        <strong v-if="feedback && feedback.correct_option_id == option.id" class="text-success d-inline-block pl-1">
          Poprawna odpowiedź
        </strong>
      </label>
    </div>
    <b-card v-if="feedback && feedback['explanation']" class="border mt-2">
      <b-card-title class="text-warning font-weight-bold" style="font-family: Arial sans-serif; font-size: 14px;">Komentarz:</b-card-title>
      <b-card-text class="quiz-black" v-html='feedback["explanation"].replace("background-color:", "").replace("background:", "")'></b-card-text>
    </b-card>
  </div>
</template>
<script>
import { BCard, BCardBody, BCardHeader, BCardText} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
    BCardHeader,
    BCardBody,
  },
  props: [
    'question',
    'feedback',
    'is_submitted',
  ],
  computed: {},
  mounted() {
    this.$emit('input', {
      question_id: this.question.id,
      option_id: null
    })
  },

  data() {
    return {
      options: this.question.options,
    }
  },
  methods: {}
}
</script>
<style>
  .quiz-black,
  .quiz-black a,
  .quiz-black p,
  .quiz-black span,
  .quiz-black sup,
  .quiz-black sub{
    color: #000000 !important;
    font-family: 'Times New Roman',serif !important;
    margin-bottom: 0 !important;
  }
</style>
