<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import plLocale from '@fullcalendar/core/locales/pl'

import {
  BCard,
  BCardText,
  BCollapse,
  BButton,
  BCardHeader,
  BCardBody,
  BModal,
  BFormCheckbox,
  BListGroup,
  BListGroupItem,
  BLink,
} from 'bootstrap-vue'
import {quillEditor} from 'vue-quill-editor'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'

// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import Quiz from './quiz/Quiz.vue'
import Curriculum from './curriculum/Curriculum'
import videojs from 'video.js';
import Reviews from "@/views/Reviews.vue";
import router from "@/router";
import Quotation from "@/views/Quotation.vue";

export default {
  components: {
    BLink,
    Quotation,
    BListGroupItem, BListGroup,
    Reviews,
    BCard,
    BCardText,
    BFormCheckbox,
    BCollapse,
    BButton,
    BCardHeader,
    BCardBody,
    quillEditor,
    Quiz,
    Curriculum,
    BModal,
    FullCalendar,
  },
  data() {
    return {
      quoatationImg: require('@/assets/images/chemmaster/dashboard/quotation.png'),
      quoatationMobileImg: require('@/assets/images/chemmaster/dashboard/quotation-mobile.png'),
      quotation: '',
      errorMessage: '',
      course: {},
      newsletterAgreement: 0,
      newsletterState: null,
      loading: true,
      currentEvent: {
        id: 0,
        title: '',
        content: '',
        date: '',
        is_done: false,
        info: {},
      },
      editorOption: {
        theme: 'snow',
        placeholder: 'Miejsce na Twoje notatki',
        modules: {
          toolbar: '#toolbar-container',
        },
      },
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, listPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          start: 'sidebarToggle, prev,next, title',
          end: 'dayGridMonth,listMonth',
        },
        initialDate: '2024-02-01',
        fixedWeekCount: false,
        locale: plLocale,
        events: [],
        eventDisplay: 'block',
        eventColor: '#1facbb',
        eventClick: this.handleDateClick,
      },
      note: '',
    }
  },
  computed: {
    dummyLesson() {
      return {
        type: 'curriculum',
        title: 'curriculum 1',
        curriculum: {
          id: 1,
          name: 'curriculum 1',
          groups: [
            {
              id: 1,
              name: 'group 1',
              items: [
                {
                  id: 1,
                  content: 'item 1',
                  checked: true,
                },
                {
                  id: 2,
                  content: 'item 2',
                  checked: false,
                },
              ],
            },
            {
              id: 2,
              name: 'group 2',
              items: [
                {
                  id: 3,
                  content: 'item 3',
                  checked: true,
                },
              ],
            },
          ],
        },
      }
    },
    lessonType() {
      if (typeof this.$store.getters['app/getCurrentCourse'].type !== 'undefined') {
        return this.$store.getters['app/getCurrentCourse'].type
      }

      return 'home'
    },
    lessonTitle() {
      if (typeof this.$store.getters['app/getCurrentCourse'] !== 'undefined') return this.$store.getters['app/getCurrentCourse'].title

      return ''
    },
    lessonContent() {
      if (typeof this.$store.getters['app/getCurrentCourse'] !== 'undefined') return this.$store.getters['app/getCurrentCourse'].content

      return ''
    },
    lessonNotate() {
      return this.$store.getters['app/getCurrentCourse'].note
    },

    lesson() {
      if (typeof this.$store.getters['app/getCurrentCourse'] === 'undefined') return ''
      return this.$store.getters['app/getCurrentCourse']
    },
    currentQuiz() {
      if (typeof this.$store.getters['app/getCurrentCourse'] !== 'undefined')
        return this.$store.getters['app/getCurrentCourse'].quiz

      return ''
    },
    lessonChallengeEvents() {
      if (typeof this.$store.getters['app/getCurrentCourse'].challenge_items !== 'undefined') {
        return this.$store.getters['app/getCurrentCourse'].challenge_items.map(item => ({
          id: item.id,
          title: item.user_challenge_done ? `✔ ${item.name}` : item.name,
          date: item.date,
          content: item.content,
          is_done: item.user_challenge_done,
          color: item.user_challenge_done ? '#1FACBB' : '#3AC7D6',
        }))
      }
      return []
    },
  },
  watch: {
    lesson(newLesson, oldLesson) {
      this.setupPlayer()
    },
    lessonNotate(newNotate, oldNotate) {
      this.note = newNotate
    },
    lessonChallengeEvents(newEvents, oldEvents) {
      this.calendarOptions.events = newEvents
      this.calendarOptions.dateClick = this.handleDateClick
    },
  },
  mounted() {
    // this.$emit('changeRouteTitle', 'nameOfYourPage')
    this.setupPlayer()
  },
  beforeDestroy() {
    if (this.players)
      for (let player of this.players) {
        player.dispose()
      }
  },
  created() {
    this.fetchCourse(this.$route.params.slug)
    this.settings()

    window.addEventListener('hashchange', event => {
      const hash_id = window.location.hash
      const trimed_hash_id = parseInt(hash_id.replace('#', ''))

      if(isNaN(trimed_hash_id)) {
        this.$store.commit('app/UPDATE_CURRENT_COURSE', {
          type: 'home'
        })
      } else if(this.$store.getters['app/getCurrentCourse'].id !== trimed_hash_id) {
        this.showLessonId(trimed_hash_id)
      }
    });

  },
  methods: {
    showLesson(lesson) {
      const course_id = this.$store.getters['app/getCourseId'];
      this.$http.get(`${this.$store.state.apiDomain}/api/course/${course_id}/lesson/${lesson.id}`)
          .then(response => {
            this.$store.commit('app/UPDATE_CURRENT_COURSE', response.data.lesson)
            window.location.hash = lesson.id
            this.isVerticalMenuActive = false;
          })
    },
    showLessonId(lesson_id) {
      const course_id = this.$store.getters['app/getCourseId'];
      this.$http.get(`${this.$store.state.apiDomain}/api/course/${course_id}/lesson/${lesson_id}`)
          .then(response => {
            this.$store.commit('app/UPDATE_CURRENT_COURSE', response.data.lesson)
            window.location.hash = lesson.id
            this.isVerticalMenuActive = false;
          })
    },
    setAsRead(id) {
      this.$http.post(`${this.$store.state.apiDomain}/api/my/notifications/setAsRead`, { notification_id: id }).then(response => {
        router.push({ name: 'notifications', query: { id: id } })
      })
    },
    settings() {
      this.$http.get(`${this.$store.state.apiDomain}/api/settings`).then(response => {
        if(response.data.status === 'ok') {
          this.quotation = response.data.quotation
        }
      })
    },
    setupPlayer() {
      setTimeout(() => {
        document.querySelectorAll('.video-js').forEach(video => {
          this.player = videojs(video, {
            playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 2],
          })
          // clear size
          video.setAttribute('style', '')
        }, 500)
      })
    },
    handleDateClick(info) {
      info.jsEvent.preventDefault()

      this.currentEvent = {
        id: info.event._def.publicId,
        title: info.event._def.title,
        content: info.event._def.extendedProps.content,
        is_done: info.event._def.extendedProps.is_done,
        date: info.event._def.date,
        info,
      }
      this.$refs['event-modal'].show()
    },
    fetchCourse(slug) {
      this.$http.get(`${this.$store.state.apiDomain}/api/my/course-async/${slug}`).then(response => {

        this.course = response.data;
        this.$store.commit('app/UPDATE_COURSE_ID', this.course.id)
        this.$store.commit('app/UPDATE_COURSE', this.course)
        this.$store.commit('app/UPDATE_CUSTOM_LAYOUT_MENU', response.data.sections)
        this.$store.commit('app/UPDATE_CUSTOM_LAYOUT_MENU', response.data.sections)

        this.$store.commit('app/UPDATE_CURRENT_COURSE', {
          type: 'home'
        })

        this.showNewsletter()
        this.loading = false;
      }).catch(error => {
        this.errorMessage = error.response.data.message;
      })
    },
    showNewsletter() {
      const modalShown = localStorage.getItem(`modal_showd_${this.course.id}`)
      const maxAge = 60000 * 60 * 24 // 1 day
      const expiryDate = Date.now() + maxAge

      if (this.course.show_newsletter && modalShown <= Date.now()) {
        this.$refs['newsletter-modal'].show()
        localStorage.setItem(`modal_showd_${this.course.id}`, expiryDate)
      }
    },
    appendArrow() {
      this.note += '→'
    },
    saveData() {
      const data = {
        lesson_id: this.$store.getters['app/getCurrentCourse'].id,
        note: this.note,
      }

      this.$http.post(`${this.$store.state.apiDomain}/api/lesson/set-note`, data).then(response => {
        if (response.status === 200) {
          this.$bvToast.toast('Notatka zapisana pomyślnie', {
            title: 'Zapis',
            variant: 'success',
          })
        } else {
          this.$bvToast.toast('Wystąpił błąd podczas zapisu notatki', {
            title: 'Zapis',
            variant: 'danger',
          })
        }
      })
    },
    changeStatusForDone() {
      this.currentEvent.info.el.innerHTML = `${'<div class="fc-event-main">'
          + '<div class="fc-event-main-frame">'
          + '<div class="fc-event-title-container">'
          + '<div class="fc-event-title fc-sticky">'
          + '✔ '}${this.currentEvent.title.replace('✔ ', '')
          }</div>`
          + '</div>'
          + '</div>'
          + '</div>'
      this.currentEvent.info.el.style.backgroundColor = '#1FACBB'
      this.currentEvent.info.el.style.color = '#ffffff'
    },
    challengeMakeDone() {
      const data = {
        challenge_item_id: this.currentEvent.id,
        is_done: true,
      }

      this.$http.post(`${this.$store.state.apiDomain}/api/lesson/toggle-challenge-done`, data).then(response => {
        if (response.status === 200) {
          this.$bvToast.toast('Zadanie zakończone', {
            title: 'Zapis',
            variant: 'success',
          })
          this.$refs['event-modal'].hide()
          this.changeStatusForDone()
        } else {
          this.$bvToast.toast('Wystąpił błąd podczas połączenia do serwera', {
            title: 'Zapis',
            variant: 'danger',
          })
        }
      })
    },
    newsletter(accepted) {
      if (!this.newsletterAgreement && accepted) {
        this.newsletterState = false
        return
      }

      const data = {
        course_id: this.course.id,
        accepted,
      }

      this.$http.post(`${this.$store.state.apiDomain}/api/course/toggle-newsletter`, data).then(response => {
        if (response.status === 200) {
          this.$refs['newsletter-modal'].hide()
        }
      })
    },
  },
}
</script>

<template>
  <div>
    <div class="lesson-content" v-if="errorMessage">
      <div class="alert alert-danger p-2">
        <h3>{{errorMessage}}</h3>
      </div>
    </div>
    <div class="lesson-content h-100" v-if="lessonType == 'home'" style="min-height: 400px;">
      <div class="atom h-100" v-if="loading" style="margin-top: 150px;">
        <div class="electron"></div>
        <div class="electron"></div>
        <div class="electron"></div>
      </div>
      <div class="row" v-if="!loading">
        <div class="col-sm-12">
          <div v-html="course.dashboard_content"></div>
        </div>
        <div class="col-sm-8">
          <b-card :title="course.curriculum.title" v-if="course.curriculum">
            <div
                v-for="lesson in course.curriculum.lessons"
                :key="lesson.id"
                :item="lesson"
                class="pt-0 pb-0 d-flex flex-row bd-highlight mb-0 lesson-item"
                @click="showLesson(lesson)"
                style="margin-bottom: 5px !important;"
            >
              <div style="margin-right: 7px;">
                <feather-icon icon="FlagIcon" size="18" />
              </div>
              <b-link
                  class="d-flex align-content-start py-0 px-0 flex-column"
                  style="margin-bottom: 7px;"
              >
                <span class="menu-title text-wrap font-medium-1"
                      v-bind:style="{ color: lesson.color ? lesson.color : '#625f6e', fontWeight: lesson.bold ? 'bold' : 'normal'}">
                  {{ lesson.title }}
                </span>
              </b-link>
            </div>
          </b-card>
          <Quotation :quoatation-img="quoatationImg"
                     :quoatation-mobile-img="quoatationMobileImg"
                     :quotation="quotation"/>
        </div>
        <div class="col-sm-4">
          <b-card title="Powiadomienia" style="max-height: 600px;overflow-y: auto;">
            <b-list-group>
              <b-list-group-item
                  class="flex-column align-items-start"
                  v-for="item in course.notifications"
                  :key="item.id"
                  style="margin-bottom: 10px;border-top-width: 1px">
                <a href="#" @click="setAsRead(item.id)" class="d-flex flex-column  w-100 justify-content-between">
                  <p class="media-heading mb-0">
                    <span class="font-weight-bolder" v-if="!item.read" style="color: #444;">
                      {{ item.title }}
                    </span>
                    <span class="font-weight-normal" v-if="item.read" style="color: #666;">
                      {{ item.title }}
                    </span>
                  </p>
                  <div>
                    <small class="notification-text" style="color: #333;">{{ item.course_name }}</small>
                  </div>
                </a>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </div>
      </div>
    </div>
    <div class="lesson-content" v-if="lessonType == 'quiz'">
      <b-card :title="lessonTitle">
        <b-card-text v-html="lessonContent">
          {{ lessonContent }}
        </b-card-text>
        <b-card-text>
          <Quiz
            :quiz="lesson.quiz"
            :refillable="true"
            :key="lessonTitle"
          />
        </b-card-text>
      </b-card>
    </div>
    <div class="lesson-content" v-if="lessonType == 'challenge'">
      <b-card :title="lessonTitle">
        <b-card-text v-html="lessonContent">
          {{ lessonContent }}
        </b-card-text>
        <FullCalendar
            ref="calsession"
            :options="calendarOptions"
        />

        <b-modal
            ref="event-modal"
            hide-footer
            :title="currentEvent.title"
        >
          <div
              class="d-block"
              v-html="currentEvent.content"
          />
          <b-button
              class="mt-2"
              variant="outline-primary"
              block
              @click="challengeMakeDone"
          >
            Zadanie wykonane
          </b-button>
        </b-modal>
      </b-card>
    </div>
    <div v-if="lesson.type == 'curriculum'">
      <b-card :title="lesson.title">
        <b-card-text v-html="lessonContent">
          {{ lessonContent }}
        </b-card-text>
        <b-card-text>
          <Curriculum :curriculum="lesson.curriculum"/>
        </b-card-text>
      </b-card>
    </div>
    <div class="lesson-content" v-if="lessonType == 'lesson' || lessonType == 'text'">
      <b-card :title="lessonTitle" style="position: relative;">
        <p v-if="!lessonContent">
          Skorzystaj z menu by wybrać lekcję lub kliknij w logo ChemMaster by wrócić do strony głównej.
        </p>
        <p style="margin-top: -7px; line-height: 14px; position: absolute; top: 25px; right: 10px;">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="12" ><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg>
          {{ lesson.time }}
        </p>
        <b-card-text v-html="lessonContent">
          {{ lessonContent }}
        </b-card-text>
      </b-card>
      <b-card
          no-body
          class="mb-1"
      >
        <b-card-body>
          <h4 class="card-title">
            Twoje notatki
          </h4>
          <div id="toolbar-container">
            <span class="ql-formats">
              <button
                  type="button"
                  class="ql-bold"
              ><svg viewBox="0 0 18 18"> <path
                  class="ql-stroke"
                  d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"
              /> <path
                  class="ql-stroke"
                  d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"
              /> </svg></button>
              <button
                  type="button"
                  class="ql-italic"
              ><svg viewBox="0 0 18 18"> <line
                  class="ql-stroke"
                  x1="7"
                  x2="13"
                  y1="4"
                  y2="4"
              /> <line
                  class="ql-stroke"
                  x1="5"
                  x2="11"
                  y1="14"
                  y2="14"
              /> <line
                  class="ql-stroke"
                  x1="8"
                  x2="10"
                  y1="14"
                  y2="4"
              /> </svg></button>
              <button
                  type="button"
                  class="ql-underline"
              ><svg viewBox="0 0 18 18"> <path
                  class="ql-stroke"
                  d="M5,3V9a4.012,4.012,0,0,0,4,4H9a4.012,4.012,0,0,0,4-4V3"
              /> <rect
                  class="ql-fill"
                  height="1"
                  rx="0.5"
                  ry="0.5"
                  width="12"
                  x="3"
                  y="15"
              /> </svg></button>
            </span>
            <span class="ql-formats">
              <button
                  type="button"
                  class="ql-list"
                  value="ordered"
              ><svg viewBox="0 0 18 18"> <line
                  class="ql-stroke"
                  x1="7"
                  x2="15"
                  y1="4"
                  y2="4"
              /> <line
                  class="ql-stroke"
                  x1="7"
                  x2="15"
                  y1="9"
                  y2="9"
              /> <line
                  class="ql-stroke"
                  x1="7"
                  x2="15"
                  y1="14"
                  y2="14"
              /> <line
                  class="ql-stroke ql-thin"
                  x1="2.5"
                  x2="4.5"
                  y1="5.5"
                  y2="5.5"
              /> <path
                  class="ql-fill"
                  d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"
              /> <path
                  class="ql-stroke ql-thin"
                  d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"
              /> <path
                  class="ql-stroke ql-thin"
                  d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"
              /> </svg></button><button
                type="button"
                class="ql-list"
                value="bullet"
            ><svg viewBox="0 0 18 18"> <line
                class="ql-stroke"
                x1="6"
                x2="15"
                y1="4"
                y2="4"
            /> <line
                class="ql-stroke"
                x1="6"
                x2="15"
                y1="9"
                y2="9"
            /> <line
                class="ql-stroke"
                x1="6"
                x2="15"
                y1="14"
                y2="14"
            /> <line
                class="ql-stroke"
                x1="3"
                x2="3"
                y1="4"
                y2="4"
            /> <line
                class="ql-stroke"
                x1="3"
                x2="3"
                y1="9"
                y2="9"
            /> <line
                class="ql-stroke"
                x1="3"
                x2="3"
                y1="14"
                y2="14"
            /> </svg></button>
            </span>
            <span class="ql-formats">
              <button
                  type="button"
                  class="ql-script"
                  value="sub"
              ><svg viewBox="0 0 18 18"> <path
                  class="ql-fill"
                  d="M15.5,15H13.861a3.858,3.858,0,0,0,1.914-2.975,1.8,1.8,0,0,0-1.6-1.751A1.921,1.921,0,0,0,12.021,11.7a0.50013,0.50013,0,1,0,.957.291h0a0.914,0.914,0,0,1,1.053-.725,0.81,0.81,0,0,1,.744.762c0,1.076-1.16971,1.86982-1.93971,2.43082A1.45639,1.45639,0,0,0,12,15.5a0.5,0.5,0,0,0,.5.5h3A0.5,0.5,0,0,0,15.5,15Z"
              /> <path
                  class="ql-fill"
                  d="M9.65,5.241a1,1,0,0,0-1.409.108L6,7.964,3.759,5.349A1,1,0,0,0,2.192,6.59178Q2.21541,6.6213,2.241,6.649L4.684,9.5,2.241,12.35A1,1,0,0,0,3.71,13.70722q0.02557-.02768.049-0.05722L6,11.036,8.241,13.65a1,1,0,1,0,1.567-1.24277Q9.78459,12.3777,9.759,12.35L7.316,9.5,9.759,6.651A1,1,0,0,0,9.65,5.241Z"
              /> </svg></button>
              <button
                  type="button"
                  class="ql-script"
                  value="super"
              ><svg viewBox="0 0 18 18"> <path
                  class="ql-fill"
                  d="M15.5,7H13.861a4.015,4.015,0,0,0,1.914-2.975,1.8,1.8,0,0,0-1.6-1.751A1.922,1.922,0,0,0,12.021,3.7a0.5,0.5,0,1,0,.957.291,0.917,0.917,0,0,1,1.053-.725,0.81,0.81,0,0,1,.744.762c0,1.077-1.164,1.925-1.934,2.486A1.423,1.423,0,0,0,12,7.5a0.5,0.5,0,0,0,.5.5h3A0.5,0.5,0,0,0,15.5,7Z"
              /> <path
                  class="ql-fill"
                  d="M9.651,5.241a1,1,0,0,0-1.41.108L6,7.964,3.759,5.349a1,1,0,1,0-1.519,1.3L4.683,9.5,2.241,12.35a1,1,0,1,0,1.519,1.3L6,11.036,8.241,13.65a1,1,0,0,0,1.519-1.3L7.317,9.5,9.759,6.651A1,1,0,0,0,9.651,5.241Z"
              /> </svg></button>
            </span>
            <span class="ql-formats">
              <button
                  type="button"
                  @click="appendArrow()"
              >→</button>
            </span>
          </div>
          <quill-editor
              v-model="note"
              :options="editorOption"
          />
          <b-button
              variant="success"
              class="mt-2"
              @click="saveData()"
          >
            Zapisz notatkę
          </b-button>
        </b-card-body>
      </b-card>
    </div>
    <div>
      <b-modal
          ref="newsletter-modal"
          no-close-on-backdrop
          hide-footer
          size="lg"
          title="Informacja"
          @close="newsletter(false)"
      >
        <div
            class="d-block"
            v-html="course.newsletter_popup_content"
        />
      </b-modal>
    </div>
  </div>
</template>

<style>
img, iframe {
  max-width: 100%;
  height: auto;
}

.fc-daygrid-event {
  white-space: normal !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
// prevent copying of text
/*.lesson-content {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}*/
/* Because of the SVG inliner, these don't work for icons */
.vjs-theme-forest {
  color: #20ad95;
}

.content-header{
  display: none;
}
.vjs-theme-forest .vjs-play-progress {
  background: #20ad95;
}

@keyframes atom {
  from { transform: none; }
  to { transform: translateY(-10px); }
}
@keyframes electron-circle1 {
  from { transform: rotateY(70deg) rotateZ(20deg); }
  to { transform: rotateY(70deg) rotateZ(380deg); }
}
@keyframes electron1 {
  from { transform: rotateZ(-20deg) rotateY(-70deg); }
  to { transform: rotateZ(-380deg) rotateY(-70deg); }
}
@keyframes electron-circle2 {
  from { transform: rotateY(60deg) rotateX(60deg) rotateZ(-30deg); }
  to { transform: rotateY(60deg) rotateX(60deg) rotateZ(330deg); }
}
@keyframes electron2 {
  from { transform: rotateZ(30deg) rotateX(-60deg) rotateY(-60deg); }
  to { transform: rotateZ(-330deg) rotateX(-60deg) rotateY(-60deg); }
}
@keyframes electron-circle3 {
  from { transform: rotateY(-60deg) rotateX(60deg) rotateZ(100deg); }
  to { transform: rotateY(-60deg) rotateX(60deg) rotateZ(460deg); }
}
@keyframes electron3 {
  from { transform: rotateZ(-100deg) rotateX(-60deg) rotateY(60deg); }
  to { transform: rotateZ(-460deg) rotateX(-60deg) rotateY(60deg); }
}

.atom {
  margin: 50px auto;
  width: 120px;
  height: 120px;
  position: relative;
  animation: atom 1s ease-in-out infinite alternate;
  perspective: 300px;
  transform-style: preserve-3d;
}
.atom:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: #20ad95;
}
.atom .electron {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: 2px solid #20ad95;
  transform-style: preserve-3d;
}
.atom .electron:before {
  content: '';
  position: absolute;
  top: -4px;
  left: 0;
  right: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #20ad95;
  transform-origin: 50% 50% 0;
}
.atom .electron:nth-child(1) {
  transform: rotateY(70deg) rotateZ(20deg);
  animation: electron-circle1 3s linear infinite;
}
.atom .electron:nth-child(2) {
  transform: rotateY(60deg) rotateX(60deg) rotateZ(-30deg);
  animation: electron-circle2 3s linear infinite;
}
.atom .electron:nth-child(3) {
  transform: rotateY(-60deg) rotateX(60deg) rotateZ(100deg);
  animation: electron-circle3 3s linear infinite;
}
.atom .electron:nth-child(1):before {
  transform: rotateZ(-20deg) rotateY(-70deg);
  animation: electron1 3s linear infinite;
}
.atom .electron:nth-child(2):before {
  transform: rotateZ(30deg) rotateX(-60deg) rotateY(-60deg);
  animation: electron2 3s linear infinite;
}
.atom .electron:nth-child(3):before {
  transform: rotateZ(-100deg) rotateX(-60deg) rotateY(60deg);
  animation: electron3 3s linear infinite;
}

</style>
