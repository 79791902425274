<template>
  <form action="" @submit="submit">
    <div class="progress-wrapper">
      <b-card-text class="mb-0">
         Ukończone: {{ curriculumItemCompletedPercent + '%' }}
      </b-card-text>
      <b-progress
          v-model="curriculumItemCompletedPercent"
          max="100"
      />
    </div>
    <div class="border p-2 my-2 flex flex-column" :key="group_idx" type="checkbox" v-for="(group, group_idx) in curriculum.groups">
      <h3 v-html="group.name"></h3>
      <div class="flex align-items-center" :key="item.id" v-for="(item) in group.items">
        <b-form-checkbox
            v-model="item.checked"
            :id="'curriculum-item'+item.id"
        >
          <label class="pl-1" :for="'curriculum-item'+item.id" v-html="item.content"></label>
        </b-form-checkbox>
      </div>
    </div>
    <button class="btn btn-success">Zapisz</button>
  </form>
</template>

<script>
import {BProgress, BCardText, BFormCheckbox} from 'bootstrap-vue'

export default {
  components: {
    BProgress,
    BCardText,
    BFormCheckbox,
  },
  name: 'Curriculum',
  props: ['curriculum'],
  computed: {
    curriculumItemCompleted() {
      let completedItems = 0;
      this.curriculum.groups.forEach(group => {
        completedItems += group.items.filter(function(item) {
          return item.checked;
        }).length;
      });

      return completedItems;
    },
    curriculumItems(){
      let items = 0;
      this.curriculum.groups.forEach(group => {
        items += group.items.length;
      });
      return items;
    },
    curriculumItemCompletedPercent(){
      return Math.round(this.curriculumItemCompleted / this.curriculumItems * 10000) / 100;
    }
  },
  methods: {
    async submit(e) {
      // no refresh
      e.preventDefault()

      // data container
      const data = {
        curriculum: this.curriculum,
      }

      this.$http.post(`${this.$store.state.apiDomain}/api/curriculumanswer/update`, data).then(response => {
        alert("Zmiany zostały zapisane");
      });
    },
  },
}
</script>

<style scoped>
  [type="checkbox"] {
    vertical-align: middle;
  }
</style>
